import React from "react";
import logoImg from "../../assets/logo.png";
import "./styles.css";

export function Politica() {
  return (
    <div className="container">
      <header className="imagem">
        <img src={logoImg} alt="Workflow" className="logoImg" />

        <h1>Informações do sitema</h1>
      
      </header>
      <div className="texto">
        <span>
          <h4>Privacidade dos Dados:</h4>
        
          a. Compromisso com a proteção dos dados pessoais dos usuários.
          <br/>
          b. Coleta mínima de informações necessárias para o funcionamento do aplicativo.
          <br/>
          c. Não compartilhamento de dados pessoais com terceiros sem consentimento explícito do usuário, exceto quando exigido por lei ou para fins de segurança pública.

          <h4>Dados de localizaçãoo:</h4>
          a. As informações de localização são coletadas para registro de ocorrências.
          <br/>
          b. Uso de criptografia para garantir a confidencialidade das informações sensíveis.
          <br/>
          c. As informações são usadas apenas para analise e estatísticas e não são repassadas para terceiros.
          <br/>

          <h4>Segurança da Informação:</h4>
          a. Implementação de medidas de segurança robustas para proteção dos dados armazenados e transmitidos.
          <br/>
          b. Uso de criptografia para garantir a confidencialidade das informações sensíveis.
          <br/>
          c. Manutenção de políticas de acesso e controle de privilégios para impedir acesso não autorizado aos dados.
          <br/>

          <h4>Armazenamento de Dados:</h4>
          a. Utilização de infraestrutura segura e confiável para o armazenamento dos dados.
          <br/>
          b. Backup regular dos dados para prevenção de perdas e garantia de disponibilidade.
          <br/>
          c. Retenção dos dados pelo tempo necessário e em conformidade com as leis de privacidade aplicáveis.

          <h4>Acesso e Autenticação:</h4>
          a. Uso de autenticação forte para garantir a identidade dos usuários.
          <br/>
          b. Implementação de controle de acesso baseado em funções para limitar o acesso aos dados apenas às pessoas autorizadas.
          <br/>
          c. Monitoramento de atividades de login e detecção de possíveis tentativas de acesso não autorizado.
          <br/>

          <h4>Atualizações de Segurança: </h4>
          a. Manutenção regular do aplicativo, incluindo aplicação de patches de segurança e atualizações de software.
          <br/>
          b. Monitoramento contínuo de vulnerabilidades e ameaças emergentes.
          <br/>
          c. Pronta resposta a incidentes de segurança e implementação de medidas corretivas adequadas.

          <h4>Treinamento e Conscientização:</h4>
          a. Conscientização dos colaboradores sobre as melhores práticas de segurança da informação.
          <br/>
          b. Treinamento regular para garantir a compreensão e conformidade com as políticas de segurança.
          <br/>
          c. Incentivo à denúncia de possíveis falhas de segurança ou violações de políticas.

          <h4>Auditoria e Conformidade:</h4>
          a. Realização de auditorias periódicas para avaliar a eficácia das medidas de segurança implementadas.
          <br/>
          b. Cumprimento das regulamentações e leis locais relacionadas à proteção de dados e privacidade.
          <br/>
          c. Transparência na divulgação das práticas de segurança para os usuários.

          <h4>Contingência e Recuperação de Dados:</h4>
          a. Desenvolvimento e teste de planos de contingência para lidar com falhas de sistema, desastres naturais ou ataques cibernéticos.
          <br/>
          b. Implementação de mecanismos de backup e recuperação de dados para minimizar a perda de informações.
        </span>
      </div>
    </div>
  );
}