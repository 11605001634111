// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtX1VOdQZDWv4NBVS_h6Y364gQSuHAk_c",
  authDomain: "prefeiturafacil-998dc.firebaseapp.com",
  projectId: "prefeiturafacil-998dc",
  storageBucket: "prefeiturafacil-998dc.appspot.com",
  messagingSenderId: "935439779137",
  appId: "1:935439779137:web:e087f68bfeb1171dae0dc0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);

export {auth, db};