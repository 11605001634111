import { useState, useEffect } from "react";
import "./styles.css";
import { Header } from "../../components/Header/header";
import { Ocorrencias } from "../Ocorrencias";
import { Relatorios } from "../Relatorios";
import { CadMensagem } from "../cadMensagem";
import { Mensagens } from "../Mensagens";

import {  FaMapMarkedAlt, FaUserPlus } from 'react-icons/fa';
import { SiGooglemessages } from 'react-icons/si';
import { Register } from "../Register";
import { useLocation } from "react-router-dom";

export function Home({email}) {
  const [mapa, setMapa] = useState(true);
  const [mensagem, setMensagem] = useState(false);
  const [cadMensagem, setCadMensagem] = useState(false);
  const [usuario, setUsuario] = useState(false);
  const [relatorios, setRelatorios] = useState(false);
  const [admin, setAdmin] = useState(false);
  const location = useLocation();
  const userEmail = location.state?.email || ''; 

  useEffect(() => {
    if (userEmail === 'hesleicosta@gmail.com') {
      setAdmin(true)
    }
  
  } , []);

  const handleMenuClick = (componente) => {
    setMapa(false);
    setMensagem(false);
    setCadMensagem(false);
    setUsuario(false);
    setRelatorios(false);
  
    switch (componente) {
      case 'ocorencias':
        setMapa(true);
        break;
      case 'Mensagens':
        setMensagem(true);
        break;
      case 'Cadastrar Mensagens':
        setCadMensagem(true);
        break;
      case 'Cadastrar Usuario':
        setUsuario(true);
        break;
      case 'Relatorios':
        setRelatorios(true);
        break;
      default:
        break;
    }
  }

  return (
    <div className="Home">
      <Header userEmail={email}/>
      <div className='main'>
        <div className='main__sidebar'>
            <div className='main__folders'>
                <div className={mapa ? 'active' : ''}>
                <div className="menu" onClick={() => handleMenuClick('ocorencias')}>
                    <FaMapMarkedAlt/> <span >Ocorrências</span>
                </div>
                </div>
                <div className={mensagem ? 'active' : ''}>
                <div className='menu' onClick={() => handleMenuClick('Mensagens')}>
                    <SiGooglemessages/> <span>Mensagens</span>
                </div>
                </div>
                <div className={cadMensagem ? 'active' : ''}>
                <div className='menu' onClick={() => handleMenuClick('Cadastrar Mensagens')}>
                    <SiGooglemessages/> <span>Cadastrar Mensagens</span>
                </div>
                </div>
                
                <div className={relatorios ? 'active' : ''}>
                <div className='menu' onClick={() => handleMenuClick('Relatorios')}>
                    <SiGooglemessages/> <span>Relatórios</span>
                </div>
                </div>
                {(admin)? (
                  <div className={usuario ? 'active' : ''}>
                  <div className='menu' onClick={() => handleMenuClick('Cadastrar Usuario')}>
                      <FaUserPlus/> <span>Cadastrar Usuário</span>
                  </div>
                  </div>
                ):<div></div> }
                
            </div>
        </div>
                {(mapa)?(
                  <Ocorrencias userEmail={userEmail}/> 
                ):
                (mensagem)?(
                    <Mensagens />
                ):
                  (cadMensagem)?(
                    <CadMensagem />
                  ):
                  (usuario)?(
                    <Register/>
                  ):
                  (relatorios)?(
                    <Relatorios />
                  ):
                      
                    <div><h2>teste</h2></div>
                }
               
      </div>
    </div>
  );
}