import { useState, useEffect } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import {  useNavigate } from "react-router-dom";
import arrowImg from "../../assets/arrow.svg";
import logoImg from "../../assets/logo.png";
import { auth } from "../../firebase";
import "./styles.css";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate()

  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  useEffect(() => {
    if (user) {
      navigate('/home', { state: { email } });
    }
  }, [user]);

  function handleSignIn(e) {
    e.preventDefault();
    signInWithEmailAndPassword(email, password);
    navigate('/home', { state: { email } });
  }

  if (loading) {
    return <p>carregando...</p>;
  }

  function handlePolitica() {
    navigate('/politica')
  }
  
  return (
    <div className="container">
      <header className="imagem">
        <img src={logoImg} alt="Workflow" className="logoImg" />
        <span>Por favor digite suas informações de login</span>
      </header>

      <form>
        <div className="inputContainer">
          <label htmlFor="email">E-mail</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Informe o email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="inputContainer">
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Informe a senha"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <a href="#">Esqueceu sua senha ?</a>

        <button className="button" onClick={handleSignIn}>
          Entrar <img src={arrowImg} alt="->" />
        </button>
        
      </form>
      <button className="button" onClick={handlePolitica}>
          politica <img src={arrowImg} alt="->" />
        </button>
    </div>
  );
}