import "leaflet/dist/leaflet.css";

import React, { useEffect, useState } from "react";
import { MapContainer , Marker, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import { collection, getDocs, query, where } from "firebase/firestore";
import {db} from '../../firebase';
import { useLocation } from "react-router-dom";
 
import mapPin from "../../assets/mapMarker.png";
import "./styles.css";

import { Header } from "../../components/Header/header";


 const mapPinIcon = Leaflet.icon({
   iconUrl: mapPin,
   iconSize: [58, 68],
   iconAnchor: [29, 68],
   popupAnchor: [170, 2],
 });
 export function Mapa({userEmail, cidade}) {
   const [ocorrencias, setOcorrencias] = useState([]);
   const location = useLocation();
   const email = location.state?.userEmail || ''; 
   const numCidade = location.state?.cidade || ''; 


   const descTipo = [
    'Iluminação publica',
    'Sinalização publica',
    'Buraco',
    'Transporte publica',
    'Coleta de Lixo',
    'Descarte irregular',
    'Queimadas',
    'Denuncia ambiental',
    'Autorização para comercio',
    'Acessibilidade',
    'Vigilancia sanitaria',
    'Denuncias'
  ]

  useEffect (()=> {
    if (email === 'hesleicosta@gmail.com') {
      load();
    }else{
      loadCidade();
    }
  },[]);

  

  async function load(){
    const querySnapshot = await getDocs(collection(db, 'ocorrencia'));
    setOcorrencias(querySnapshot.docs.map(l=>{
        //  let tipo
          let estado
          let num = l.data().tipo
        //  const tipo = descTipo.map((l.data().tipo) => key={tipoaaa});
          let numtipo = descTipo[num - 1]
          if(l.data().estado === 1){
              estado = 'Registrado'
          }
          return {
              id: l.id,
              about: l.data().about,
              estado: estado,
              tipo: numtipo,
              lat: l.data().latitude,
              lng: l.data().longitude
          }
      }))
    }

    async function loadCidade(){
      const q = query(collection(db, "ocorrencia"), where("cidade", "==", numCidade));
      const querySnapshot = await getDocs(q);
      setOcorrencias(querySnapshot.docs.map(l=>{
          //  let tipo
            let estado
            let num = l.data().tipo
          //  const tipo = descTipo.map((l.data().tipo) => key={tipoaaa});
            let numtipo = descTipo[num - 1]
            if(l.data().estado === 1){
                estado = 'Registrado'
            }
            return {
                id: l.id,
                about: l.data().about,
                estado: estado,
                tipo: numtipo,
                lat: l.data().latitude,
                lng: l.data().longitude
            }
        }))
      }
   
   return (
     <div id="page-map">
      <Header/>
       <MapContainer
         center={[-15.801824, -48.011110]}
         zoom={15}
         style={{ width: "100%", height: "100%" }}
       >
         {/* <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
         <TileLayer
         /*
           url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`}
          */
           url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGVzbGVpY29zdGEiLCJhIjoiY2w5YzF0M212NDdidTN2bnc0ZW5ibmV0OCJ9.7Zu4SDyJD3td5Ry67hnUdg`}
         />
          {ocorrencias.map(function(val){
            return (
            <Marker
            icon={mapPinIcon}
            position={[val.lat, val.lng]}
            >

            </Marker>
            )
          })}
       </MapContainer >
     </div>
   );
 }
 