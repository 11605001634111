import React, { useEffect, useState } from "react";
 import "./styles.css";

import { collection, getDocs } from "firebase/firestore";
import {db} from '../../firebase';
import { FiSend } from 'react-icons/fi';

export function Mensagens() {
  const [mensagens, setMensagens] = useState([]);

  useEffect (()=> {
    async function load(){
      const querySnapshot = await getDocs(collection(db, 'mensagens'));
      setMensagens(querySnapshot.docs.map(l=>{
            return {
                texto: l.data().texto
            }
      }))
      }
    load();
    },[]);

    async function enviarMsg() {
      alert('mensagem enviada aos usuarios.');
    }

  return (
    <div className="ocorrencias_detalhe">
      <div className='mapa'>
        <h2>Enviar mensagens</h2>
        
        {
          mensagens.map(function(val) {
            return(
              <div className='Msg_dados' onClick={enviarMsg}>
                <span className='sobre'>{val.texto}</span> <FiSend className="icon" /> 
                
              </div>
            )
          }
        )}
      </div>
    </div>
  );
}