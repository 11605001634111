import { useState } from "react";
import "./styles.css";
import { SiGooglemessages } from 'react-icons/si';

import {db} from '../../firebase'
import { collection, addDoc  } from "firebase/firestore";

export function CadMensagem() {
  const [texto, setTexto] = useState("");


  async function gravaMsg() {
    let msg = {
      texto: texto
    }
    await addDoc(collection(db, 'mensagens') , msg);
    alert('mensagem cadastrada com sucesso.');
  }

  return (
    <div className="ocorrencias_detalhe">
      <div className='mapa'>
        <h2>Cadastrar mensagens</h2>
        <form>
        <div className="mensagem">
          <input
            type="text"
            name="texto"
            id="texto"
            placeholder="Digite a mensagem a ser enviada ao usuario"
            onChange={(e) => setTexto(e.target.value)}
          />
        </div>
        <div className='ocorrenciasBotao' onClick={gravaMsg}>
          <span>Cadastrar </span>  <SiGooglemessages className="icon" /> 
        </div>
      </form>
      </div>
    </div>
  );
}