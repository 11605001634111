import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import logoImg from "../../assets/logo.png";
import { auth  } from "../../firebase";
import { signOut } from 'firebase/auth';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./styles.css";

export function Header() {
  const navigate = useNavigate()
  const [email, setUser] = useState(null);

  useEffect(()=>{
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUser(auth.currentUser.email);
        // ...
      } else {
        navigate('/');
        // User is signed out
        // ...
      }
    });
  },[])

  function handleLogout() {
    signOut(auth).then(() => {
      navigate('/');
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  function handleHomeClick() {
    navigate('/home', { state: { email } });
  }
  
  return (
    <div className="containerHeader">
        <div className="headerImg">
          
        <button onClick={handleHomeClick}>
          <img src={logoImg} alt="Logo" />
        </button>
        </div>
        <div className="headerUser">
          <span>{email}</span>
        </div>
        <div className="headerSair">
          <button className="button" onClick={handleLogout}>
            Sair 
          </button>
        </div>

    </div>
  );
}