import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Login } from "../pages/Login";
import { Home } from '../pages/Home'
import { Register } from "../pages/Register";
import { Mapa } from '../pages/Mapa';
import { Politica } from "../pages/Politica";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Mapa" element={<Mapa />} />
        <Route path="/Politica" element={<Politica />} />
      </Routes>
    </BrowserRouter>
  );
}