import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import "./styles.css";
import {  FaMapMarkedAlt } from 'react-icons/fa';
import { GrStatusGood } from 'react-icons/gr';

import { collection, getDocs, updateDoc, doc, query, where, getDoc } from "firebase/firestore";
import {db} from '../../firebase';

export function Ocorrencias({ userEmail }) {
  const [ocorrencias, setOcorrencias] = useState([]);
  const [cidade, setCidade] = useState("");
  const [atualizar, setAtualizar] = useState(false);
  let filtroEstado = 99
  let filtroTipo = 99
  const navigate = useNavigate();

  const descTipo = [
    'Iluminação publica',
    'Sinalização publica',
    'Buraco',
    'Transporte publica',
    'Coleta de Lixo',
    'Descarte irregular',
    'Queimadas',
    'Denuncia ambiental',
    'Autorização para comercio',
    'Acessibilidade',
    'Vigilancia sanitaria',
    'Denuncias'
  ]

  const descEstado = [
    'Registrado',
    'Em analise',
    'Aguradando verba',
    'Em licitação',
    'Resolvido'
  ]

  
  

  useEffect(() => {
    
      if (userEmail  === "hesleicosta@gmail.com") {
        load();
      } else {
        buscaCidade();
        loadCidade();
      }
  }, [cidade, atualizar]);

  async function buscaCidade() {
    try {
      if (!userEmail) {
        return;
      }

      console.log("userEmail = " , userEmail)
      const docRef = doc(db, "usuario", userEmail);
      const docSnapshot = await getDoc(docRef);
  
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const cidade = data.nome; 
        setCidade(cidade);
      } else {
        console.log("Documento não encontrado para o email:", userEmail);
      }
    } catch (error) {
      console.error("Erro ao buscar cidade:", error);
    }
  }

  async function load(){
    const querySnapshot = await getDocs(collection(db, 'ocorrencia'));
    const sortedDocs = querySnapshot.docs.sort((a, b) => {
      return a.data().time - b.data().time;
    });
    setOcorrencias(sortedDocs.map(l=>{
        //  let tipo
          
          let num = l.data().tipo
        //  const tipo = descTipo.map((l.data().tipo) => key={tipoaaa});
          let numtipo = descTipo[num - 1]
          num = l.data().estado

          let numEstado  = descEstado[num - 1]

          let data = new Date(l.data().time)
          const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];
          let dataFormatada = ((data.getDate() + "-" + meses[(data.getMonth())] + "-" + data.getFullYear() + " As " + data.getHours() + ":" + data.getMinutes() + ":" + data.getSeconds()))
          
          return {
              id: l.id,
              about: l.data().about,
              estado: numEstado,
              tipo: numtipo,
              instructions: l.data().instructions,
              time: dataFormatada
          }
    }))
  }

  async function loadCidade(){
    console.log("dentro do load cidade")
    console.log("cidade = ", cidade)
    const q = query(collection(db, "ocorrencia"), where("cidade", "==", cidade));
    const querySnapshot = await getDocs(q);
    const sortedDocs = querySnapshot.docs.sort((a, b) => {
      return a.data().time - b.data().time;
    });
    setOcorrencias(sortedDocs.map(l=>{
        //  let tipo
          
          let num = l.data().tipo
        //  const tipo = descTipo.map((l.data().tipo) => key={tipoaaa});
          let numtipo = descTipo[num - 1]
          num = l.data().estado

          let numEstado  = descEstado[num - 1]

          let data = new Date(l.data().time)
          const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];
          let dataFormatada = ((data.getDate() + "-" + meses[(data.getMonth())] + "-" + data.getFullYear() + " As " + data.getHours() + ":" + data.getMinutes() + ":" + data.getSeconds()))
          
          return {
              id: l.id,
              about: l.data().about,
              estado: numEstado,
              tipo: numtipo,
              instructions: l.data().instructions,
              time: dataFormatada
          }
    }))
  }

  function handleMapa(){
    navigate('/mapa',{ state: {userEmail, cidade} })
  }

  async function handleAtualizar(id,estado) {
    const ocorrenciaDoc = doc(db, "ocorrencia", id);
    await updateDoc(ocorrenciaDoc, {
      "estado": estado
    });
    setAtualizar(!atualizar);
  }

  function handleFiltroEstado(event){
    
    const selectedOption = event.target.value;
      switch (selectedOption) {
        case 'Registrado':
          filtroEstado = 1
          break;
        case 'Em analise':
          filtroEstado = 2
          break;
        case 'Aguradando verba':
          filtroEstado = 3
          break;
        case 'Em licitação':
          filtroEstado = 4
          break;
        case 'Resolvido':
          filtroEstado = 5
          break;
        default:
          filtroEstado = 99
          break;
      }
    
  }

  
  function handleFiltroTipo(event){
    
    const selectedOption = event.target.value;
      switch (selectedOption) {
        case 'Iluminação publica':
          filtroTipo = 1
          break;
        case 'Sinalização publica':
          filtroTipo = 2
          break;
        case 'Buraco':
          filtroTipo = 3
          break;
        case 'Transporte publica':
          filtroTipo = 4
          break;
        case 'Coleta de Lixo':
          filtroTipo = 5
          break;
        case 'Descarte irregular':
          filtroTipo = 6
          break;
        case 'Queimadas':
          filtroTipo = 7
          break;
        case 'Denuncia ambiental':
          filtroTipo = 8
          break;
        case 'Autorização para comercio':
          filtroTipo = 9
          break;
        case 'Acessibilidade':
          filtroTipo = 10
          break;
        case 'Vigilancia sanitaria':
          filtroTipo = 11
          break;
        case 'Denuncias':
          filtroTipo = 12
          break;

        default:
          filtroTipo = 99
          break;
      }
  }

  function filtrar(){
    if (filtroEstado !== 99) {
      if (filtroTipo !== 99) {
        loadFiltro2();
      }else{
        loadFiltroEstado()
      }
    }else{
      if (filtroTipo === 99) {
        load()
      }else {
        loadFiltroTipo()
      }
      
    }
  }

  async function loadFiltroTipo(){
    const q = query(
      collection(db, 'ocorrencia'), 
      where("tipo", "==", filtroTipo),
    );

    const querySnapshot = await getDocs(q);
    const sortedDocs = querySnapshot.docs.sort((a, b) => {
      return a.data().time - b.data().time;
    });
    setOcorrencias(sortedDocs.map(l=>{
        let num = l.data().tipo
        let numtipo = descTipo[num - 1]
        num = l.data().estado
        let numEstado  = descEstado[num - 1]
        let data = new Date(l.data().time)
        const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];
        let dataFormatada = ((data.getDate() + "-" + meses[(data.getMonth())] + "-" + data.getFullYear() + " As " + data.getHours() + ":" + data.getMinutes() + ":" + data.getSeconds()))
        return {
          id: l.id,
          about: l.data().about,
          estado: numEstado,
          tipo: numtipo,
          instructions: l.data().instructions,
          time: dataFormatada
        }
    }))
  }

  async function loadFiltroEstado(){
    const q = query(collection(db, 'ocorrencia'), 
    where("estado", "==", filtroEstado),
    );
    const querySnapshot = await getDocs(q);
    const sortedDocs = querySnapshot.docs.sort((a, b) => {
      return a.data().time - b.data().time;
    });
    setOcorrencias(sortedDocs.map(l=>{
        let num = l.data().tipo
        let numtipo = descTipo[num - 1]
        num = l.data().estado
        let numEstado  = descEstado[num - 1]
        let data = new Date(l.data().time)
        const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];
        let dataFormatada = ((data.getDate() + "-" + meses[(data.getMonth())] + "-" + data.getFullYear() + " As " + data.getHours() + ":" + data.getMinutes() + ":" + data.getSeconds()))
        return {
          id: l.id,
          about: l.data().about,
          estado: numEstado,
          tipo: numtipo,
          instructions: l.data().instructions,
          time: dataFormatada
        }
    }))
  }

  async function loadFiltro2(){
    const q = query(collection(db, 'ocorrencia'), 
    where("estado", "==", filtroEstado),
    where("tipo", "==", filtroTipo),
    );
    const querySnapshot = await getDocs(q);
    const sortedDocs = querySnapshot.docs.sort((a, b) => {
      return a.data().time - b.data().time;
    });
    setOcorrencias(sortedDocs.map(l=>{
        let num = l.data().tipo
        let numtipo = descTipo[num - 1]
        num = l.data().estado
        let numEstado  = descEstado[num - 1]
        let data = new Date(l.data().time)
        const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];
        let dataFormatada = ((data.getDate() + "-" + meses[(data.getMonth())] + "-" + data.getFullYear() + " As " + data.getHours() + ":" + data.getMinutes() + ":" + data.getSeconds()))
        return {
          id: l.id,
          about: l.data().about,
          estado: numEstado,
          tipo: numtipo,
          instructions: l.data().instructions,
          time: dataFormatada
        }
    }))
  }
  

  return (
    <div className="ocorrencias_detalhe">
      <div className='mapa'>
        <h2>Ocorrências</h2>
        <div className='ocorrenciasBotao' onClick={handleMapa}>
          <span>Ver no mapa </span>  <FaMapMarkedAlt className="icon" /> 
        </div>
        <div className="filtro">
          <h3>Filtrar por </h3>
          <label htmlFor="estado">Estado:</label>
          <select name="estado" id="estado" onChange={handleFiltroEstado}>
            <option value="">Todos</option>
            {descEstado.map(function(est) {
              return(
                <option value={est}>{est}</option>
              )
            })}
          </select>
          <label htmlFor="tipo">Tipo:</label>
          <select name="tipo" id="tipo" onChange={handleFiltroTipo}>
            <option value="">Todos</option>
            {descTipo.map(function(est) {
              return(
                <option value={est}>{est}</option>
              )
            })}
          </select>
          <button onClick={filtrar}>Filtrar</button>
        </div>
        <div className='cabecalho'>
          <span className='sobre'>Sobre</span>
          <span className='tipo'>Tipo</span>
          <span className='data'>Data</span>
          <span className='estado'>Estado</span>
        </div>
        {
          ocorrencias.map(function(val) {
            return(
              <div className='dados' key={val.id}>
                <span className='sobre'>{val.about}</span>
                <span className='tipo'>{val.tipo}</span>
                <span className='data'>{val.time}</span>
                <span className='estado'>{val.estado}</span>
                <div className='atualizar' >
                  <GrStatusGood  />
                  <form>
                  <input type='button' value='Em analise' onClick={() => handleAtualizar(val.id, 2)}/>
                  <input type='button' value='Agurdando verba' onClick={() => handleAtualizar(val.id, 3)}/>
                  <input type='button' value='Em licitação' onClick={() => handleAtualizar(val.id, 4)}/>
                  <input type='button' value='Resolvido' onClick={() => handleAtualizar(val.id, 5)}/>
                  </form>
                </div>

              </div>
            )
          }
        )}
      </div>
    </div>
  );
}