import { useState, useEffect } from "react";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import arrowImg from "../../assets/arrow.svg";
import logoImg from "../../assets/logo.png";
import { auth } from "../../firebase";
import "./styles.css";
import { doc, setDoc } from "firebase/firestore";
import {db} from '../../firebase'

export function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateNum, setSelectedStateNum] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityNum, setSelectedCitynum] = useState(0);

  useEffect(() => {
    // Busca os estados da API do IBGE
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados/?orderBy=nome")
      .then((response) => response.json())
      .then((data) => setStates(data));
  }, []);

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    const stateNome = event.target.options[event.target.selectedIndex].text;
    setSelectedState(stateNome);
    setSelectedStateNum(stateId);

    // Busca as cidades do estado selecionado na API do IBGE
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios?orderBy=nome`
    )
      .then((response) => response.json())
      .then((data) => setCities(data));
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    const cityNome = event.target.options[event.target.selectedIndex].text;
    setSelectedCitynum(cityId);
    setSelectedCity(cityNome);

  }; 

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  function handleCreate(e) {
    e.preventDefault();
    createUserWithEmailAndPassword(email, password);
    handleSave();
  }

  

  const handleSave = async () => {
    if (selectedState && selectedCity) {
      try {
        // Cria o documento do estado na coleção "estados"
        await setDoc(doc(db, "estados", selectedState), {});

        // Cria o documento do município na subcoleção "municipios"
        await setDoc(doc(db, "estados", selectedState, "municipios", selectedCity), {
          nome: selectedCity,
          codigo: parseInt(selectedCityNum),
          ativo: true,
        });

        await setDoc(doc(db, "usuario", email), {
          nome: selectedCity,
          codigo: parseInt(selectedCityNum),
        });

        console.log("Dados cadastrados com sucesso!");
      } catch (error) {
        console.error("Erro ao cadastrar os dados: ", error);
      }
    }
  };

  

  if (loading) {
    return <p>carregando...</p>;
  }
  return (
    <div className="container">
        <img src={logoImg} alt="Workflow" className="logoImgReg" />
        <span>Por favor digite suas informações de cadastro</span>
        <div className="selecao">
        <label>
        Estado:
        <select value={selectedStateNum} onChange={(event) => handleStateChange(event)}>
          <option value="">Selecione o estado</option>
          {states.map((state) => (
            <option key={state.id} value={state.id}>
              {state.nome}
            </option>
          ))}
        </select>
      </label>

      {selectedState && (
        <label>
          Município:
          <select value={selectedCityNum} onChange={handleCityChange}>
            <option value="">Selecione o município</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.nome}
              </option>
            ))}
          </select>
        </label>
      )}
      </div>

      <form>
        <div className="inputContainer">
          <label htmlFor="email">E-mail</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="johndoe@gmail.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="inputContainer">
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="********************"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button onClick={handleCreate} className="button">
          Cadastrar <img src={arrowImg} alt="->" />
        </button>
      </form>
    </div>
  );
}