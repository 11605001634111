import React, { useEffect, useState } from "react";
import "./styles.css";

import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase';
import ReactApexChart from 'react-apexcharts';

export function Relatorios() {
  const [ocorrencias, setOcorrencias] = useState([]);
  const [tipoValues, setTipoValues] = useState([]);

  useEffect(() => {
    const fetchOcorrencias = async () => {
      const ocorrenciasRef = collection(db, "ocorrencia");
      const ocorrenciasSnapshot = await getDocs(ocorrenciasRef);
      const ocorrenciasData = ocorrenciasSnapshot.docs.map((doc) => doc.data());
      setOcorrencias(ocorrenciasData);

      const tipoSet = new Set(ocorrenciasData.map((ocorrencia) => ocorrencia.tipo));
      const uniqueTipos = Array.from(tipoSet);
      setTipoValues(uniqueTipos);
    };

    fetchOcorrencias();
  }, []);

  const estadoLabels = {
    1: 'Registrados',
    2: 'Em análise',
    3: 'Aguardando Verba',
    4: 'Em Licitação',
    5: 'Resolvidos',
  };

  const groupedData = ocorrencias.reduce((groups, ocorrencia) => {
    const date = new Date(ocorrencia.time);
    const formattedDate = date.toISOString().split('T')[0];

    if (!groups[formattedDate]) {
      groups[formattedDate] = Object.values(estadoLabels).reduce((counts, label) => {
        counts[label] = 0;
        return counts;
      }, {});
    }

    const label = estadoLabels[ocorrencia.estado];
    groups[formattedDate][label] += 1;

    return groups;
  }, {});

  const sortedData = Object.entries(groupedData).sort(([dateA], [dateB]) => {
    const timeA = new Date(dateA).getTime();
    const timeB = new Date(dateB).getTime();
    return timeA - timeB;
  });

  const chartData = sortedData.flatMap(([date, labelCounts]) =>
    Object.entries(labelCounts).map(([label, count]) => ({
      x: date,
      y: count,
      label,
    }))
  );

  const chartHeight = Math.max(300, sortedData.length * 50);

  const chartOptions = {
    chart: {
      type: 'bar',
      height: chartHeight,
    },
    series: Object.values(estadoLabels).map((label) => ({
      name: label,
      data: chartData.filter((data) => data.label === label),
    })),
    xaxis: {
      type: 'category',
    },
  };

  const pieLabels = {
    1: 'Iluminação pública',
    2: 'Sinalização pública',
    3: 'Buraco',
    4: 'Transporte público',
    5: 'Coleta de Lixo',
    6: 'Descarte irregular',
    7: 'Queimadas',
    8: 'Denúncia ambiental',
    9: 'Autorização para comércio',
    10: 'Acessibilidade',
    11: 'Vigilância sanitária',
    12: 'Denúncias',
  };

  const tipoCounts = tipoValues.reduce((counts, tipo) => {
    counts[tipo] = ocorrencias.filter((ocorrencia) => ocorrencia.tipo === tipo).length;
    return counts;
  }, {});

  const totalCount = Object.values(tipoCounts).reduce((sum, count) => sum + count, 0);

  const pieData = Object.entries(tipoCounts).map(([tipo, count]) => ({
    x: tipo,
    y: count,
    percentage: totalCount !== 0 ? ((count / totalCount) * 100).toFixed(2) : 0,
  }));

  const pieOptions = {
    chart: {
      type: 'pie',
    },
    series: pieData.map((data) => data.y),
    labels: pieData.map((data) => `${pieLabels[data.x]} (${data.percentage}%)`),
    legend: {
      position: 'bottom',
    },
  };

  return (
    <div className="relatorios">
      <h2>Relatórios</h2>
    <div>
      <div className="barra" id="chart">
      <h4>Registro de Ocorrências</h4>
        <ReactApexChart
          options={chartOptions}
          series={chartOptions.series}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
          width={1000}
        />
      </div>

      <div className="pizza" id="tipo-chart">
      <h4>Tipo de Ocorrências</h4>
        <ReactApexChart
          options={pieOptions}
          series={pieOptions.series}
          type={pieOptions.chart.type}
          width={1000}
        />
      </div>
    </div>
    </div>
  );
}
